import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, getSrc } from "gatsby-plugin-image"

import Layout from "../components/layout/layout"
import HeroSection from "../components/common/hero-section"
import Seo from "../components/seo"

const pStyle = "text-lg md:text-xl md:px-12 mb-6"
const dateStyle = "text-xs md:px-12 mb-6"

const PageBlogPost2 = () => {
  const data = useStaticQuery(graphql`
    query PageBlogPost2Query {
      imgHero: file(relativePath: { eq: "backgrounds/blog-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const imgHero = getImage(data.imgHero)
  const imgSrc = getSrc(data.imgHero)

  return (
    <Layout>
      <Seo
        title="Using Captives to Insure Against Black Swan Events"
        description="In case you missed it - Sometimes, third-party commercial insurance policies are not always enough."
        image={imgSrc}
      />
      <HeroSection
        title="USING CAPTIVES TO INSURE AGAINST BLACK SWAN EVENTS"
        imgSrc={imgHero}
      />

      <div className="py-20">
        <div className="w-11/12 lg:w-10/12 max-w-screen-lg mx-auto">
          <p className={dateStyle}>August 4, 2020</p>
          <p className={pStyle}>In case you missed it - Sometimes, third-party commercial insurance policies are not always enough. These policies often contain exclusions that prevent coverage during the time it is most needed and can lead to a claim being denied. With programs like ours, we help protect against these black swan risks.</p>
          <p className={pStyle}><a href="http://www.riskmanagementmonitor.com/using-captives-to-insure-against-black-swan-events/" target="_blank" rel="noopener noreferrer" className="text-site-red underline">Read more</a></p>
        </div>
      </div>
    </Layout>
  )
}

export default PageBlogPost2
